/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react';
import ApplicationDetails from '../../Components/ApplicationPageComponents/ApplicationDetails/ApplicationDetails';
import ApplicationForm from '../../Components/ApplicationPageComponents/ApplicationForm/ApplicationForm';
import ApplicationStatus from '../../Components/ApplicationPageComponents/ApplicationStatus/ApplicationStatus';
import LayoutApplicationPage
    from '../../Components/ApplicationPageComponents/LayoutApplicationPage/LayoutApplicationPage';
import PreviousApplicationMenu
    from '../../Components/ApplicationPageComponents/PreviousApplicationMenu/PreviousApplicationMenu';
import {push} from 'connected-react-router'
import {useDispatch, useSelector} from "react-redux";
import {
    addDetailsToApplicationInProcess, addTheCommentToSpecialist, getClientName,
    getCurrentApplicationData, getCurrentApplicationDataSuccess, getHashOfTheLastApplication,
    getLastApplication,
    getLastApplicationFullData,
    getReturningApplicationData,
    setReturningApplicationId,
    leaveTheRate,
    postNewApplication, saveId,
    setApplicationBackInProgress, setApplicationHash, setLastApplicationIsBack, setMyInterval
} from "../../Store/ApplicationsReducer/applicationsActions";
import {FooterInfo} from "../../constants/Contact/FooterInfo/FooterInfo";
import {ITSGContact} from "../../constants/Contact/ITSGContact/ITSGContact";
import {
    ApplicationNotFoundCenter,
    ApplicationNotFoundTop
} from "../../Components/ApplicationPageComponents/ApplicationNotFound/ApplicationNotFound";
import FunctionBlock from '../../Components/UI/FunctionBlock/FunctionBlock';
import './ApplicationPage.css'
import AccessAccessApplicationDetails from '../../Components/OpenAccessPageComponent/AccessApplicationDetails/AccessApplicationDetails';
import FormBlock from '../../Components/UI/FormBlock/FormBlock';
import FormBlockSubtitle from '../../Components/UI/FormBlock/FormBlockSubtitle/FormBlockSubtitle';
import FormBlockTitle from '../../Components/UI/FormBlock/FormBlockTitle/FormBlockTitle';
import HrModalWindow from '../../Components/OpenAccessPageComponent/HRModalWindow/HrModlaWindow';
import FaqSidebar from '../../Components/UI/FaqSidebar/FaqSidebar';
import { getQuestionsName, setFaqOpen, setShowReturnApplicationModal, showFaq } from '../../Store/GeneralReducer/generalActions';
import ApplicationSpecialist from '../../Components/UI/ApplicationSpecialist/ApplicationSpecialist';
import { useLocation } from 'react-router';
import ChatOpenButton from '../../Components/ApplicationPageComponents/Chat/ChatMessage/ChatOpenButton/ChatOpenButton';
import Container from '../../Components/UI/Container/Container';
import RedButton from '../../Components/UI/RedButton/RedButton';
import ChatAddFiles from '../../Components/ApplicationPageComponents/Chat/ChatAddFiles/ChatAddFiles';
import { getApplicationExists, getChatApplicationInfo, getChatApplicationTime, setApplicationExists, setNotificationsWorking } from '../../Store/ChatReducer/chatActions';
import { useParams } from 'react-router/cjs/react-router.min';
import { checkSubscribtion, reqForNotif, unregisterServiceWorkers } from '../../Components/ApplicationPageComponents/Chat/WebPush/WebPush';
import { CONFIG } from '../../constants/backendUrl';
import { sendYMAnalytics } from '../../utils/metrika';
import { getVksAvailability } from '../../Store/VksReducer/vksActions';
import WhiteButton from '../../Components/ApplicationPageComponents/WhiteButton/WhiteButton';
import { getIntegrator } from '../../Store/BIReducer/biActions';

//TODO получить имя клиента по id для приветсвия когда создаем новую заявку, какой запрос отправляем
//TODO Посмотреть вернувшуюся в работу заявку, это модалка с данными, где взять данные по вернувшейся заявке?
//TODO Если статус "Отменено" и мы возвращаем заявку обратно, что происходит
//TODO Можно ли прикреплять файл к созданной заявке?
const ApplicationPage = React.memo((props) => {
    const dispatch = useDispatch();
    const id = props.match.params.id;
    const company_device_id = id.substring(0, id.indexOf('-'));
    const applicationHash = props.match.params.hash;
    const addFilesModal = useSelector(state => state.applications.addFilesModal)
    const [oneComment, setOneComment] = useState("");
    const [specialistComment, setSpecialistComment] = useState('')
    const refFile = useRef();
    const [fileNameState, setFileNameState] = useState([]);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [showQuestion, setShowQuestion] = useState(false);
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const {lastApplicationIsBack} = useSelector(state=>state.applications)
    const [isExpiredDate, setIsExpiredDate] = useState(false);
    const [isBackInProgress, setIsBackInProgress] = useState(lastApplicationIsBack);
    const currentApplicationExists = useSelector(state=>state.chat.applicationExists)
    const [hrModal, setHrModal] = useState(false);
    const [seconds, setSeconds] = useState('00');
    const [minutes, setMinutes] = useState(15);
    const [minutesNumber, setMinutesNumber] = useState(15)
    let buttonName = "";
    const [showChat, setShowChat] = useState(false)
    let description = "";
    const title = "Предыдущая заявка";
    let center;
    let top;
    let leftSide;
    const [moreInfo, setMoreInfo]=useState(false)
    const lastApplication = useSelector(state => state.applications.lastApplication);
    const currentApplication = useSelector(state => state.applications.currentApplicationData);
    const clientName = useSelector(state => state.applications.clientName)
    const faq = useSelector(state=>state.general.faq)
    const [reviewForm, toggleReviewForm] = useState(false);
    const [commentButtonVissibility, setCommentButtonVisibility] = useState(false)
    const [isLike, setIsLike] = useState(false)
    const [isDisLike, setIsDisLike] = useState(false)
    const [isHoverReview, setModalReview] = useState(false);
    const [ connectionType, setConnectionType] = useState(localStorage.getItem("connectionType")?localStorage.getItem("connectionType"):"tv")
    const [showConnectVars, setShowConnectVars] = useState(false)
    const setConnection = (type) =>{
        setConnectionType(type);
        setShowConnectVars(false)
    }
    
    const [serviceWorkers, setServiceWorkers] = useState({
        adress: "",
        model: ""
    })
    let timer = null;
    let newApplication = currentApplication?.status === 'Запланировано';
    const parseTimerTime = (totalTime) => {
        if (totalTime === "expired") return 0
        const timeArray = totalTime.split(":")
        return ((parseInt(timeArray[0] * 60)) + parseInt(timeArray[1])) * 1000
    }

    let timerDuration=currentApplication?.timer ? parseTimerTime(currentApplication.timer) : null
    
    const openChat = () =>{
        setShowChat(true)
        sendYMAnalytics("asista-chat-open", {id: id})
    }
    const closeChat = () => {
        setShowChat(false)
    }

    const isLikeHandler = () => {
        setIsLike(true);
        setIsDisLike(false);
        let obj = {
            document: applicationHash,
            rate: "1",
            companyName: "itsupport"
        };
        dispatch(leaveTheRate(obj));
    };
    
    const isDisLikeHandler = () => {
        setIsDisLike(true);
        setIsLike(false);
        let obj = {
            document: applicationHash,
            rate: "-1",
            companyName: "itsupport"
        };
        dispatch(leaveTheRate(obj));
    };
    const togglePopUp = () => {
        toggleReviewForm(!reviewForm);
    };

    const [showHint, setShowHint] = useState({
        department: false
    })
    function useQuery() {
        const { search } = useLocation();
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }
    const query = useQuery()
    useEffect(()=>{
        if(query.get("openComment")==="1"){
            toggleReviewForm(true)
        }
        if(query.get("openChat")==="1"){
            openChat()
        }
    }, [query])
    useEffect(()=>{
        dispatch(getQuestionsName())
    }, [dispatch])
    
    useEffect(() => {
        unregisterServiceWorkers(`${CONFIG.appDomain}/application/`)
        clearInputState();
        dispatch(getClientName(id));
        dispatch(getLastApplication(id));
        dispatch(saveId(id));
        if (lastApplication?.date !== null && lastApplication?.date)
            dateExpirationHandler(lastApplication.date);

    }, [dispatch, id]);

    useEffect(() => {
        dispatch(getVksAvailability({hash: applicationHash, id: id}))
        if(applicationHash==="newticket"){
            dispatch(getCurrentApplicationDataSuccess({}))
            dispatch(setApplicationExists(false))
        }
        if (applicationHash && applicationHash!=="newticket") {
            
            dispatch(getCurrentApplicationData(applicationHash));
            dispatch(getApplicationExists({hash: applicationHash, deviceId: id}))
            dispatch(getChatApplicationTime({hash: applicationHash, deviceId: id}))
            dispatch(setApplicationHash(applicationHash));
            window.scrollTo(0, 0)
        }
    }, [applicationHash, dispatch, reviewForm])

    useEffect(()=>{
        if(applicationHash!=="newticket"){
            dispatch(getCurrentApplicationData(applicationHash));
            dispatch(setApplicationHash(applicationHash));
        }
        
    }, [timer])
    useEffect(() => {
        if (currentApplication?.rate === 1) {
            setIsLike(true);
            setIsDisLike(false);
        } else if (currentApplication?.rate===-1) {
            setIsDisLike(true);
            setIsLike(false);
        }
        currentApplication?.comment? setCommentButtonVisibility(true): setCommentButtonVisibility(false);
    }, [currentApplication?.comment, isDisLike, isLike, currentApplication]);

    useEffect(() => {
        if (newApplication) {
            if (timerDuration > 0 && !timer) {
                timer = setInterval(() => {
                    let seconds = (timerDuration % 60000) / 1000;
                    let minutes = Math.floor(timerDuration / 60000);
                    setMinutesNumber(minutes)
                    if (seconds < 10) {
                        seconds = '0' + seconds;
                    }
                    if (minutes < 10) {
                        minutes = '0' + minutes;
                    }
                    setSeconds(seconds);
                    setMinutes(minutes);
                    
                    timerDuration -= 1000;
                    if (minutes === '00' && seconds === '00') {
                        clearInterval(timer);
                    }
                }, 1000);
            }
            if (timerDuration <= 0) {
                clearInterval(timer);
                setSeconds('00');
                setMinutes('00');
                timer = null;
            }
            return () => {
                clearInterval(timer);
            };
        }
    }, [currentApplication]);
    const [inputState, setInputState] = useState({
        id: id,
        problem: '',
        section: 'В какой отдел отправить заявку?',
        message: '',
        tvpass: '',
        files: [],
        companyName: "itsupport"
        // personalData: false
    })
    useEffect(()=>{
        const anydesk_id = localStorage.getItem("anydesk_id")
        if(anydesk_id && connectionType==="anydesk"){
            setInputState({...inputState, tvpass: anydesk_id})
        }else{
            setInputState({...inputState, tvpass: ""})
        }
    }, [connectionType])
    useEffect(()=>{
        localStorage.setItem("connectionType", connectionType)
    }, [connectionType])
    
    if (lastApplicationIsBack) {
        description = `Заявка вернулась в работу, ИТ-специалист свяжется с вами в ближайшее время`;
        buttonName = "Посмотреть заявку в новом окне";
    } else {
        buttonName = "Вернуть в работу";
    }

    const hoverShowQuestion = () => {
        setShowQuestion(true);
    }

    const hoverHideQuestion = () => {
        setShowQuestion(false);
    }

    const toggleShowPassword = () => {
        if (isShowPassword) {
            setIsShowPassword(false);
        } else {
            setIsShowPassword(true);
        }
    }
    const chooseFile = (event) => {
        const fileNameStateCopy = [...fileNameState]
        for (let i = 0; i < event.target.files.length; i++) {
            fileNameStateCopy.push(event.target.files[i].name)
            setFileNameState(fileNameStateCopy);
            const filesCopy = inputState.files
            filesCopy.push(event.target.files[i])
            setInputState(prevState => {
                return {...prevState, "files": filesCopy}
            });
        }
    }

    const activateFileInput = () => {
        refFile.current.click();
    }

    const dateExpirationHandler = (date) => {
        let currMonth = new Date().getMonth() + 1;
        let currDay = new Date().getUTCDate();
        let applicationDateMonth = Number(date.slice(3, 5));
        let applicationDateDay = Number(date.slice(0, 3));
        let diff =
            (currMonth - applicationDateMonth) * 31 + currDay - applicationDateDay;
        if (Math.abs(diff) >= 31) {
            setIsExpiredDate(true);
        }
    };

    const deleteFile = (i) => {
        const fileNameStateCopy = [...fileNameState]
        fileNameStateCopy.splice(i, 1)
        setFileNameState(fileNameStateCopy)
        const filesCopy = inputState.files
        filesCopy.splice(i, 1)
        setInputState(prevState => {
            return {...prevState, "files": filesCopy}
        });
    }

    const inputHandler = (event) => {
        const {name, value} = event.target;
        if (value !== "DEFAULT" && event?.target?.style) {
            event.target.style.color = "#4D525B"
        }
        setInputState(prevState => {
            return {...prevState, [name]: value}
        });
    }

    const checkboxHandler = (event) => {
        const {name, checked} = event.target;
        setInputState(prevState => {
            return {...prevState, [name]: checked}
        });
        setCheckboxChecked(checked);
    }

    const clearInputState = () => {
        setFileNameState([])
        setInputState({
            id: id,
            problem: '',
            section: '',
            message: '',
            tvpass: '',
            files: [],
            companyName:"itsupport"
            // personalData: false
        });
    }


    const isBackInProgressHandler = () => {
        if (!lastApplicationIsBack) {
            dispatch(getLastApplicationFullData(lastApplication.ref))
            dispatch(setShowReturnApplicationModal(true));
        }
    }
    const currentApplicationBackInProgressHandler = () => {
        
        dispatch(getReturningApplicationData(applicationHash))
        dispatch(setReturningApplicationId(applicationHash))
        dispatch(setShowReturnApplicationModal(true));
        
    }
    const submitFormHandler = async (event) => {
        event.preventDefault()
        let inputStateCopy = {...inputState}
        

        inputStateCopy.message += "\n"
        if(inputStateCopy.section==="2" || inputStateCopy.section==="12"){
            let moreToMessage = "";
            serviceWorkers.adress!=="" ? moreToMessage += `Адрес: ${serviceWorkers.adress}\n` : moreToMessage += "";
            serviceWorkers.model!==""  ? moreToMessage += `Модель принтера: ${serviceWorkers.model}\n` : moreToMessage += "";
            inputStateCopy.message = inputStateCopy.message + moreToMessage;
        }else{
            console.log(inputStateCopy);
        }

        const formData = new FormData();
        Object.keys(inputStateCopy).forEach(key => {
            if (typeof inputStateCopy[key] === 'object' && inputStateCopy[key] !== null) {
                for (let i = 0; i < inputStateCopy[key].length; i++) {
                    formData.append(key + "[]", inputStateCopy[key][i], inputStateCopy[key][i].name);
                }
            } else {
                formData.append(key, inputStateCopy[key]);
            }
        });
        console.table(inputStateCopy);
        dispatch(postNewApplication(formData, id, false));

        clearInputState();

    }
    // открыть вернувшуюся заявку в новом окне
    const goToReturnedApplication = async () => {
        clearInputState();
        dispatch(setLastApplicationIsBack(false))
        dispatch(push(`/application/${id}/${lastApplication.ref}`))

    }

    const isDisabled = () => {
        setSubmitDisabled(false);
        Object.keys(inputState).forEach(key => { 
            if (key !== "files" && key !== "tvpass" && key !== "companyName") {
                if (!inputState[key]) {
                    setSubmitDisabled(true);
                }
            }
        });
    }
    const textAreaHandler = (event) => {
        setOneComment(event.target.value);
    }
    const applyComment = async () => {
        if (oneComment === undefined || oneComment.trim() === '') {
            return;
        }

        const obj = {
            document: applicationHash,
            body: oneComment
        }
        await dispatch(addDetailsToApplicationInProcess(obj))
        setOneComment('')
        await dispatch(getCurrentApplicationData(applicationHash))
    }

    useEffect(() => {
        isDisabled();
    }, [inputState, isDisabled]);
    

    let interval = useRef();

    useEffect(() => {
        if (currentApplication ? !!currentApplication.result : false) {
            interval.current = setInterval(() => {
                dispatch(getCurrentApplicationData(applicationHash))
                dispatch(getApplicationExists({hash: applicationHash, deviceId: id}))
                dispatch(getChatApplicationTime({hash: applicationHash, deviceId: id}))
                dispatch(setMyInterval(interval.current));
            }, 10000);
            if (currentApplication.status === 'Завершено' || currentApplication.status === 'Отменено') {
                clearInterval(interval.current);
            }
        } else {
            clearInterval(interval.current);
        }
        return () => {
            clearInterval(interval.current);
        }
    }, [applicationHash, currentApplication, dispatch])
    
    const goToApplication = () => {
        
        if (id) {
            dispatch(push(`/prelogin/${id}`));
        } else {
            dispatch(push(`/prelogin/anonymous`));
        }
        clearInterval(interval.current);
        dispatch(getHashOfTheLastApplication(""))
        dispatch(getCurrentApplicationData(""))
        clearInputState()
    };
    const goToHistoryOfApplications = () => {
        clearInterval(interval.current);
        dispatch(getCurrentApplicationData(""))
        dispatch(push("/search"));
        clearInputState()
    };

    let fileListBlocks;
    if (fileNameState.length) {
        fileListBlocks = fileNameState.map((el, i) => {
            return <div key={el} className="FileListBlocks__item">
                <p className="FileListBlocks__text">{el}</p>
                <div onClick={() => {
                    deleteFile(i)
                }} className="FileListBlocks__iconDelete"/>
            </div>
        })
    }
    
    const applyCommentToSpecialist = async (e) => {
        // e.preventDefault();
        if (specialistComment === undefined || specialistComment.trim() === '') {
            return;
        }
        const data = {
            document: applicationHash,
            comment: specialistComment,
            companyName: "itsupport"
        }
        await dispatch(addTheCommentToSpecialist(data))

        toggleReviewForm(false)
        setCommentButtonVisibility(true)
        togglePopUp(false)
    }
    const setNotificationsWorkingFunc = (arg) => {
        dispatch(setNotificationsWorking(arg))
    }
    const fetchIntegrator = (company_device_id) => {
        dispatch(getIntegrator(company_device_id))
    }
    useEffect(()=>{
        checkSubscribtion(setNotificationsWorkingFunc)
        fetchIntegrator(company_device_id)
    }, [])
    useEffect(()=>{
        if(currentApplicationExists){
            // if((!localStorage.getItem("reqForNotificationsSent") || localStorage.getItem("reqForNotificationsSent")!=="true" ) ){
                reqForNotif(`Guest:${id}:${applicationHash}`, setNotificationsWorkingFunc)
            // }
        }
        
        
    }, [id,currentApplicationExists, applicationHash, localStorage])
    if (currentApplication ? currentApplication.result : null) {
        if (currentApplication.humanId) {
            top = (
                <>
                    <ApplicationSpecialist 
                        currentApplication = {currentApplication} 
                        isLike = {isLike}
                        isDisLike = {isDisLike}
                        isLikeHandler = {isLikeHandler}
                        isDisLikeHandler = {isDisLikeHandler}
                        isHoverReview={isHoverReview}
                        togglePopUp = {togglePopUp}
                        commentButtonVissibility = {commentButtonVissibility}
                        clientID={id}
                        setReviewFormFalse = {()=>toggleReviewForm(false)}
                        reviewForm = {reviewForm}
                        minutes={minutes}
                        seconds={seconds}
                        needTimer={true}
                        onChangeComment={
                            setSpecialistComment
                        }
                        commentValue={specialistComment}
                        applyCommentToSpecialist = {()=>applyCommentToSpecialist()}
                        clientName={clientName.name}
                        newApplication = {newApplication}
                    />
                    {
                        currentApplicationExists? <ChatOpenButton 
                        openChat={openChat} 
                        closeChat={closeChat} 
                        showChat={showChat}
                        deviceId={id} appHash={applicationHash}/> 
                        : 
                        <div className='WaitingForChat'>Чат с поддержкой станет доступен через некоторое время</div>
                    }
                    
                </>
                
            )
        } else {
            top = (
                <ApplicationNotFoundTop/>
            )
        }
    }
    if (currentApplication ? currentApplication.result : null) {
        leftSide = (
            <ApplicationStatus
                id={id}
                onPause={currentApplication.status === 'Пауза'}
                specialistFound={currentApplication.status === 'В работе'}
                jobDone={currentApplication.status === 'Завершено'}
                isCanceled={currentApplication.status === 'Отменено'}
                backInProgress={() => {
                    currentApplicationBackInProgressHandler()
                }}
            />
        )
    } else if (lastApplication && !!lastApplication.result) {
        leftSide = (
            <>
                <PreviousApplicationMenu
                    title={title}
                    name={lastApplication.topic}
                    date={lastApplication.date}
                    description={description}
                    buttonName={buttonName}
                    clicked={
                        lastApplicationIsBack ? goToReturnedApplication : isBackInProgressHandler
                    }
                />
                {
                clientName?.access?.includes('employers')?
                <WhiteButton
                    name="Прием или увольнение сотрудника"
                    clicked={()=>{setHrModal(true)}}
                />:null
                
                }
                
            </>
            
     
        );

    }else{
        leftSide = (
            <>
                
                {
                clientName?.access?.includes('employers')?
                <WhiteButton
                    name="Прием или увольнение сотрудника"
                    clicked={()=>{setHrModal(true)}}
                />:null
                }
                
            </>
            
            
        );
    }
    if (currentApplication ? currentApplication.result : null) {
        if (currentApplication.humanId && !currentApplication.jsonBody) {
            center = (
            <ApplicationDetails
                showChat={showChat}
                department={currentApplication.division}
                subject={currentApplication.topic}
                message={currentApplication.body}
                result={currentApplication.eventresult}
                showDetailsButton={currentApplication.body ? currentApplication.body.length > 100 : null}
                showResultButton={currentApplication.eventresult ? currentApplication.eventresult.length > 100 : null}
                oneComment={oneComment}
                onChangeComment={(event) => {
                    textAreaHandler(event)
                }}
                submitComment={applyComment}
                idInTitle={currentApplication ? currentApplication.humanId : null}
                jobDone={currentApplication.status === 'Завершено'}
                isCanceled={currentApplication.status === 'Отменено'}
            />
            )
        } else if(currentApplication.humanId && currentApplication.jsonBody){
            
            const jsonbody = JSON.parse(currentApplication.jsonBody)
            if(jsonbody.applicationType==="openAccess"){
                center=(
                    <AccessAccessApplicationDetails
                        showChat={showChat}
                        department={currentApplication.division}
                        subject={currentApplication.topic}
                        message={currentApplication.body}
                        result={currentApplication.eventresult}
                        showDetailsButton={currentApplication.body ? currentApplication.body.length > 100 : null}
                        showResultButton={currentApplication.eventresult ? currentApplication.eventresult.length > 100 : null}
                        oneComment={oneComment}
                        onChangeComment={(event) => {
                            textAreaHandler(event)
                        }}
                        submitComment={applyComment}
                        idInTitle={currentApplication ? currentApplication.humanId : null}
                        jobDone={currentApplication.status === 'Завершено'}
                        isCanceled={currentApplication.status === 'Отменено'}
                    > 
                    
                    
                    
                    {
                        jsonbody.user?
                        <FormBlock title="Данные нового пользователя">
                            <FormBlockSubtitle>{jsonbody?.user.fullname}</FormBlockSubtitle>
                            <FormBlockSubtitle>{jsonbody?.user.phone}</FormBlockSubtitle>
                            <FormBlockSubtitle>{jsonbody?.user.job.title}</FormBlockSubtitle>
                            <FormBlockSubtitle>{jsonbody?.user.job?.dateEmployment}</FormBlockSubtitle>
                        </FormBlock>
                        :null
                    }      
                    {moreInfo?
                    <>   
                        {
                            (jsonbody.email && (jsonbody.email?.value || jsonbody.email?.task))?
                                <FormBlock title="Учетная запись ПОЧТЫ">
                                    {jsonbody.email.value?<FormBlockSubtitle>{jsonbody.email?.value}</FormBlockSubtitle>:null}
                                    {jsonbody.email.groups?<FormBlockSubtitle>{jsonbody.email?.groups}</FormBlockSubtitle>:null}
                                    {}
                                </FormBlock>
                            :null
                        }
                        {
                            (jsonbody.access.server?.createAccount || jsonbody.access.server?.remoteAccess || (jsonbody.access?.server?.folders.length>0))?
                            <FormBlock title="Доступ к серверу">
                            {
                                jsonbody.access.server.createAccount?
                                <div className='block__checked'>
                                    <div className='checkbox'><div className='checkbox_active'></div></div>
                                    <FormBlockSubtitle>Создать учетную запись на сервере </FormBlockSubtitle>
                                </div>
                                
                                :null
                            }
                            {
                                jsonbody.access.server.remoteAccess?
                                <div className='block__checked'>
                                    <div className='checkbox'><div className='checkbox_active'></div></div>
                                    <FormBlockSubtitle>Нужен внешний доступ к серверу</FormBlockSubtitle>
                                </div>
                                :null
                            }
                            
                            {
                                (jsonbody.access.server.folders && jsonbody.access.server.folders[0]?.name)?
                                <FormBlockTitle>Доступ к общим папкам</FormBlockTitle>
                                :null
                            }
                            
                            {
                                (jsonbody.access.server.folders && jsonbody.access.server.folders[0]?.name)?
                                jsonbody.access.server.folders.map((folder, index) =>{
                                    return(
                                        <div className='flex_row' key={folder.access+index}>
                                            <FormBlockSubtitle>{folder.name}</FormBlockSubtitle>
                                            <FormBlockSubtitle>{folder.access}</FormBlockSubtitle>
                                        </div>
                                    )
                                }):null
                            }
    
                            
                        </FormBlock>:null
                        }
                        
    
                        {
                            (jsonbody.access.buh_1c.iconColor || jsonbody.access.buh_1c.path)?
                                <FormBlock title="Доступ к 1С">
                                    {
                                        jsonbody.access.buh_1c.iconColor==="yellow"?
                                        <FormBlockSubtitle>Желтый значок</FormBlockSubtitle>:
                                        jsonbody.access.buh_1c.iconColor==="blue"?
                                        <FormBlockSubtitle>Синий значок</FormBlockSubtitle>:
                                        null
                                    }
                                    {
                                        jsonbody.access.buh_1c.path?<FormBlockSubtitle>{jsonbody.access.buh_1c.path}</FormBlockSubtitle>
                                        :null
                                    }
                                </FormBlock>
                            :null
                        }
                        
                        {
                            (jsonbody.access.corpPhone.access || jsonbody.access.corpPhone.needDevice)?
                                <FormBlock>
                                    {
                                        jsonbody.access.corpPhone.access?<FormBlockTitle>Создать внутренний номер</FormBlockTitle>
                                    :null
                                    }
                                    {
                                        jsonbody.access.corpPhone.number?<FormBlockSubtitle>{jsonbody.access.corpPhone.number}</FormBlockSubtitle>
                                        :null
                                    }
                                    {
                                        jsonbody.access.corpPhone.needDevice?<FormBlockSubtitle>Установить новый телефонный аппарат</FormBlockSubtitle>
                                        :null
                                    }
                                </FormBlock>
                            :null
                        }
                        {
                            jsonbody.revokeLicenses?
                            <FormBlock title = "Отозвать лицензии">
                                <FormBlockSubtitle>{jsonbody.revokeLicenses}</FormBlockSubtitle>
                            </FormBlock>
                            :null
                        }
                        {
                            (jsonbody.software.msoffice.version || jsonbody.software.msoffice.apps.length>0 || jsonbody.software.msoffice.another)?
                                <FormBlock title="Установить ПО">
                                    {
                                        jsonbody.software.msoffice.version?<FormBlockSubtitle>{jsonbody.software.msoffice.version}</FormBlockSubtitle>
                                        :null
                                    }
                                    {
                                        jsonbody.software.msoffice.apps?
                                        jsonbody.software.msoffice.apps.map(app=>(
                                            <FormBlockSubtitle key={app}>{app}</FormBlockSubtitle>
                                        ))
                                        :null
                                    }
                                    {
                                        jsonbody.software.msoffice.another?<FormBlockSubtitle>{jsonbody.software.msoffice.another}</FormBlockSubtitle>
                                        :null
                                    }
                                </FormBlock>
                            :null
                        }
                        {
                            (jsonbody.software.antivirus.fromList.length>0 || jsonbody.software.antivirus.otherName)?
                                <FormBlock title="Антивирус">
                                    {
                                        jsonbody.software.antivirus.fromList!=="Другое"?
                                            <FormBlockSubtitle>{jsonbody.software.antivirus.fromList}</FormBlockSubtitle>:
                                        jsonbody.software.antivirus.fromList==="Другое"?
                                            <FormBlockSubtitle>{jsonbody.software.antivirus.otherName}</FormBlockSubtitle>
                                        :null
                                    }
                                </FormBlock>    
                            :null
                        }
                        {
                            (jsonbody.software.goverment.fromList.length>0 || jsonbody.software.goverment.otherName)?
                                <FormBlock title="Налоговое ПО">
                                    {
                                        jsonbody.software.goverment.fromList.map(goverment=> {
                                        if(goverment==="Другой портал"){
                                            return null
                                        }else{
                                            return <FormBlockSubtitle key={goverment}>{goverment}</FormBlockSubtitle>
                                        }
                                        }
                                        )
                                    }
                                    {
                                        jsonbody.software.goverment.otherName?<FormBlockSubtitle>{jsonbody.software.goverment.otherName}</FormBlockSubtitle>:null
                                    }
                                </FormBlock>    
                            :null
                        }
                        {
                            (jsonbody.software.bank)?
                                <FormBlock title="Банковские программы">
                                    <FormBlockSubtitle>{jsonbody.software.bank}</FormBlockSubtitle>
                                </FormBlock>    
                            :null
                        }
                        {
                            (jsonbody.software.utilites.fromList.length>0 || jsonbody.software.utilites.otherName)?
                                <FormBlock title="Дополнительное ПО">
                                    {
                                        jsonbody.software.utilites.fromList?
                                        jsonbody.software.utilites.fromList.map(utilite =>(
                                            <FormBlockSubtitle key={utilite}>{utilite}</FormBlockSubtitle>
                                        )):null
                                    }
                                    {jsonbody.software.utilites.otherName?<FormBlockSubtitle>{jsonbody.software.utilites.otherName}</FormBlockSubtitle>:null}
                                </FormBlock>
                            :null
                        }
                        {
                            jsonbody.comment?
                            // <div className='comment_wrapper'>
                            //     <FormBlockTitle>Дополнительный комментарий</FormBlockTitle>
                            //     <p className='commentResponse'>
                            //         {jsonbody.comment}
                            //     </p>
                            // </div>
                            <FormBlock title="Дополнительный комментарий">
                                <p>{jsonbody.comment}</p>
                            </FormBlock>
                            :null
                            
                        }
                        <button className='moreInfo' type="button" onClick={()=>{setMoreInfo(false)}}>...скрыть подробности</button>
                    </>:<button className='moreInfo' type="button" onClick={()=>{setMoreInfo(true)}}>...показать подробнее</button>}
                    </AccessAccessApplicationDetails>
                )
            }else if(jsonbody.applicationType==="changeAccess"){
                
                center=(
                    <AccessAccessApplicationDetails
                        showChat={showChat}
                        department={currentApplication.division}
                        subject={currentApplication.topic}
                        message={currentApplication.body}
                        result={currentApplication.eventresult}
                        showDetailsButton={currentApplication.body ? currentApplication.body.length > 100 : null}
                        showResultButton={currentApplication.eventresult ? currentApplication.eventresult.length > 100 : null}
                        oneComment={oneComment}
                        onChangeComment={(event) => {
                            textAreaHandler(event)
                        }}
                        submitComment={applyComment}
                        idInTitle={currentApplication ? currentApplication.humanId : null}
                        jobDone={currentApplication.status === 'Завершено'}
                        isCanceled={currentApplication.status === 'Отменено'}
                    > 
                    
                    
                    
                        {
                            jsonbody.user?
                            <FormBlock title="Данные пользователя">
                                <FormBlockSubtitle>{jsonbody?.user.fullname}</FormBlockSubtitle>
                                <FormBlockSubtitle>{jsonbody?.user.phone}</FormBlockSubtitle>
                            </FormBlock>
                            :null
                        } 
                        {moreInfo?
                            <>
                            {
                                jsonbody.device?
                                <FormBlock title="Данные оборудования">
                                    {jsonbody.device.oldId?<FormBlockSubtitle>Старое оборудование: {jsonbody.device.oldId}</FormBlockSubtitle>:null}
                                    {
                                        jsonbody.device.used?<FormBlockSubtitle>Новым оборудованием будет оборудование: {jsonbody.device.newId}</FormBlockSubtitle>:<FormBlockSubtitle>Новому оборудование нужен новый id</FormBlockSubtitle>
                                    }
                                </FormBlock>:null
                            }
                            {
                                jsonbody.needMove?
                                <FormBlock title="Нужно перенести">
                                    {
                                        jsonbody.needMove
                                    }
                                </FormBlock>:null
                            }
                            {
                                jsonbody.comment?
                                    <FormBlock title="Дополнительный комментарий">
                                        <p>{jsonbody.comment}</p>
                                    </FormBlock>
                                :null

                            }
                            <button className='moreInfo' type="button" onClick={()=>{setMoreInfo(false)}}>...скрыть подробности</button>
                        </>
                           :<button className='moreInfo' type="button" onClick={()=>{setMoreInfo(true)}}>...показать подробнее</button> }     
                        
                    </AccessAccessApplicationDetails>
                )
            }else if(jsonbody.applicationType==="closeAccess"){
                const jsonbody = JSON.parse(currentApplication.jsonBody)
                center=(
                    <AccessAccessApplicationDetails
                        showChat={showChat}
                        department={currentApplication.division}
                        subject={currentApplication.topic}
                        message={currentApplication.body}
                        result={currentApplication.eventresult}
                        showDetailsButton={currentApplication.body ? currentApplication.body.length > 100 : null}
                        showResultButton={currentApplication.eventresult ? currentApplication.eventresult.length > 100 : null}
                        oneComment={oneComment}
                        onChangeComment={(event) => {
                            textAreaHandler(event)
                        }}
                        submitComment={applyComment}
                        idInTitle={currentApplication ? currentApplication.humanId : null}
                        jobDone={currentApplication.status === 'Завершено'}
                        isCanceled={currentApplication.status === 'Отменено'}
                    > 

                    
                        {
                            jsonbody.user?
                            <FormBlock title="Данные пользователя">
                                <FormBlockSubtitle>{jsonbody?.user.fullname}</FormBlockSubtitle>
                            </FormBlock>
                            :null
                        }   
                        {moreInfo?   
                            <>
                                {
                                    (jsonbody.device && jsonbody.device.id)?
                                    <FormBlock title="Данные оборудования">
                                        <FormBlockSubtitle>{jsonbody.device.id}</FormBlockSubtitle>
                                    </FormBlock>:null
                                }
                                {
                                    (jsonbody.email && (jsonbody.email?.value || jsonbody.email?.task))?
                                        <FormBlock title="Учетная запись ПОЧТЫ *">
                                            {jsonbody.email.value?<FormBlockSubtitle>{jsonbody.email?.value}</FormBlockSubtitle>:null}
                                            {jsonbody.email.task?<FormBlockSubtitle>Нужно: {jsonbody.email?.task}</FormBlockSubtitle>:null}
                                        </FormBlock>
                                    :null
                                }
                                {
                                    (jsonbody.access.server && (jsonbody.access.server.blockAccount || jsonbody.access.server.blockRemoteAccess))?
                                        <FormBlock title="Учетная запись ПОЧТЫ *">
                                            {
                                                jsonbody.access.server.blockAccount?
                                                <div className='block__checked'>
                                                    <div className='checkbox'><div className='checkbox_active'></div></div>
                                                    <FormBlockSubtitle>Заблокировать учетную запись на сервере </FormBlockSubtitle>
                                                </div>
                                                
                                                :null
                                            }
                                            {
                                                jsonbody.access.server.blockRemoteAccess?
                                                <div className='block__checked'>
                                                    <div className='checkbox'><div className='checkbox_active'></div></div>
                                                    <FormBlockSubtitle>Заблокировать внешний доступ к серверу</FormBlockSubtitle>
                                                </div>
                                                
                                                :null
                                            }
                                        </FormBlock>
                                    :null
                                }
                                {
                                    (jsonbody.access.corpPhone && (jsonbody.access.corpPhone.block || jsonbody.access.corpPhone.number))?
                                        <FormBlock title="Доступ к телефонии">
                                            {
                                                jsonbody.access.corpPhone.block?<FormBlockSubtitle>Заблокировать 
                                                    {jsonbody.access.corpPhone.number?
                                                        <span> телефон {jsonbody.access.corpPhone.number}</span>
                                                    :null}

                                                </FormBlockSubtitle>
                                                :null
                                            }
                                        </FormBlock>
                                    :null
                                }
                                {
                                    jsonbody.revokeLicences?<FormBlock title="Отозвать лицензии">{jsonbody.revokeLicences}</FormBlock>:null
                                }


                                {
                                    jsonbody.comment?
                                        <FormBlock title="Дополнительный комментарий">
                                            <p>{jsonbody.comment}</p>
                                        </FormBlock>
                                    :null

                                }
                                <button className='moreInfo' type="button" onClick={()=>{setMoreInfo(false)}}>...скрыть подробности</button>
                            </>
                            :<button className='moreInfo' type="button" onClick={()=>{setMoreInfo(true)}}>...показать подробнее</button> 
                        }   
                        
                    </AccessAccessApplicationDetails>
                )
            }
            
        }else {
            center = (
                <ApplicationNotFoundCenter/>
            )
        }
    } else {
        center = (
            <>
                <ApplicationForm
                    userName={clientName.result ? clientName.name : "Anonymous"}
                    greetings={"здравствуйте! Опишите свою проблему."}
                    subjectValue={inputState.problem}
                    subjectTitle={"Тема*"}
                    subjectName="problem"
                    subjectChange={(event) => {
                        inputHandler(event)
                    }}
                    subjectRequired={true}
                    subjectPlaceholder={"Опишите кратко суть проблемы"}

                    departmentTitle={"Отдел*"}
                    departmentFaq={"Нажмите, чтобы узнать как выбрать отдел"}
                    hintOnClick={()=>{
                        dispatch(showFaq(true));
                        dispatch(setFaqOpen({...faq.questions, department: true}))
                    }}
                    departmentFaqHintState = {showHint.department} 
                    setHintShow={()=>{setShowHint({...showHint, department: true})}}
                    setHintHidden={()=>{setShowHint({...showHint, department: false})}}
                    departmentName="section"
                    departmentValue={inputState.section}
                    departmentChange={(event) => {
                        inputHandler(event)
                    }}
                    departmentRequired={true}
                    departmentPlaceholder={"В какой отдел отправить заявку?"}
                    onChangeAdress = {(e)=>setServiceWorkers({...serviceWorkers, adress: e.target.value})} 
                    adressValue={serviceWorkers.adress}
                    onChangePrinterModel = {(e)=>setServiceWorkers({...serviceWorkers, model: e.target.value})} 
                    modelValue={serviceWorkers.model}
                    messageTitle={"Сообщение*"}
                    messageName="message"
                    messageChange={(event) => {
                        inputHandler(event)
                    }}
                    messageRequired={true}
                    messagePlaceholder={"Расскажите подробнее, например: утром вайфай еще работал, а после обеда выключается каждые пять минут отправляю письма, а они не доходят до получателей. Можно прикрепить к сообщению снимок экрана. Это поможет нам разобраться в проблеме."}

                    fileClicked={chooseFile}
                    iconClick={activateFileInput}
                    fileRef={refFile}
                    fileName={"Добавить файлы"}
                    inputFileName="file"
                    questionShow={hoverShowQuestion}
                    questionHide={hoverHideQuestion}
                    questionHintState={showQuestion}
                    questionText={"Прикрепите необходимые по вашему мнению файлы"}
                    passwordName="tvpass"
                    connectionValue={inputState.tvpass}
                    setConnectionTv={()=>{setConnection("tv")}}
                    setConnectionAny={()=>{setConnection("anydesk")}}
                    connectionType={connectionType}
                    showConnectVars={showConnectVars}
                    toggleShowConnectVars={()=>setShowConnectVars(!showConnectVars)}
                    connectionOnChange={(event) => {
                        setInputState(prevState=>{
                            return {...prevState, tvpass: event.target.value}
                        })
                    }}
                    connectionOnChangeAnydesk={(event) => {
                        localStorage.setItem("anydesk_id", event.target.value)
                        setInputState(prevState=>{
                            return {...prevState, tvpass: event.target.value}
                        })
                    }}
                    buttonName={"Отправить заявку"}
                    submitClicked={(event) => {
                        submitFormHandler(event)
                    }}
                    isDisabled={submitDisabled}
                    chosenFiles={fileListBlocks}
                    // personalDataName = "personalData"
                    onChange={(e) => checkboxHandler(e)}
                    checked={checkboxChecked}
                />
                {
                    hrModal?
                    <HrModalWindow 
                        bgOnClick = {()=>{setHrModal(false)}}
                        modalStatus = {hrModal}
                        id={id}
                    />
                    :null
                }
                
            </>
            
        )
    }

    let bottom = (
        <>
            <FooterInfo/>
            <ITSGContact/>
        </>
    )

    return (
        <>

            <LayoutApplicationPage
                
                id={id}
                goToApplicationHistory={goToHistoryOfApplications}
                createNewApplication={goToApplication}
                left={leftSide}
                center={center}
                top={top}
                bottom={bottom}
                hideButton={currentApplication ? !currentApplication.result : true}
            >
            </LayoutApplicationPage>
            <FaqSidebar 
                questions = {faq.questions}
                openState = {faq.openState}
                showFaq = {faq.show}
                dropdown={setFaqOpen}
                showFaqOnClick = {()=>{dispatch(showFaq(true))}}
                goBackOnClick = {()=>{dispatch(showFaq(false))}}
                faqFeedback={faq.feedback}
            />
            
            
        </>
    )
})

export default ApplicationPage;